import { getGuessStatuses } from '../../lib/statuses'
import { Cell } from './Cell'
import tw from 'twrnc';
import { View } from 'react-native';

type Props = {
  guess: string,
  solution: string
}

export const CompletedRow = ({ guess, solution }: Props) => {
  const statuses = getGuessStatuses(guess, solution)

  return (
    <View style={tw`"flex justify-center mb-1 flex-row`}>
      {guess.split('').map((letter, i) => (
        <Cell key={i} value={letter} status={statuses[i]} />
      ))}
    </View>
  )
}
