import {
  InformationCircleIcon,
  RefreshIcon,
  SunIcon,
  XIcon,
} from 'react-native-heroicons/outline'
import { useState, useEffect } from 'react'
import tw from 'twrnc';
import { Grid } from './components/grid/Grid'
import { Alert } from './components/alerts/Alert';
import { Keyboard } from './components/keyboard/Keyboard'
import { AboutModal } from './components/modals/AboutModal'
import { InfoModal } from './components/modals/InfoModal'
import { StatsModal } from './components/modals/StatsModal'
import { Cell } from './components/grid/Cell'
import {
  WIN_MESSAGES,
  GAME_COPIED_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  WORD_NOT_FOUND_MESSAGE,
  CORRECT_WORD_MESSAGE,
  GAME_TITLE,
} from './constants/strings'
import { isWordInWordList } from './lib/words'

import dbref from './firebase'
import {ref, onValue, set, child, push} from "firebase/database";
import {getAuth} from "firebase/auth";
import { View, Text, TouchableOpacity, SafeAreaView, Alert as ReactAlert } from 'react-native';

const ALERT_TIME_MS = 2000

type Props = {
  gameId: string
  myFbUserId: string
  exitGame: Function
}

function Game({gameId, myFbUserId, exitGame}:Props) {
  const prefersDarkMode = false;

  const [currentGuess, setCurrentGuess] = useState('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false)
  const [isNotEnoughLetters, setIsNotEnoughLetters] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isWordNotFoundAlertOpen, setIsWordNotFoundAlertOpen] = useState(false)
  const [isGameLost, setIsGameLost] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(false)
  const [swapBoardOverride, setSwapBoardOverride] = useState(false)

  const [myPlayerIdx, setMyPlayerIdx] = useState(-1);
  const [isMyTurn, setIsMyTurn] = useState(false);
  const [needToChooseWord, setNeedToChooseWord] = useState(true);
  const [waitingOnOpponentToChooseWord, setWaitingOnOpponentToChoosWord] = useState(false);
  const [solution, setSolution] = useState("FLARE");
  const [opponentGuesses, setOpponentGuesses] = useState([]);
  const [opponentSolution, setOpponentSolution] = useState("FLARI");
  const [opponentCurrentGuess, setOpponentCurrentGuess] = useState("");
  const [opponentUserId, setOpponentUserId] = useState("");
  const [opponentName, setOpponentName] = useState("");

  const [successAlert, setSuccessAlert] = useState('')
  const [guesses, setGuesses] = useState<string[]>(() => {
    // const loaded = loadGameStateFromLocalStorage()
    // if (loaded?.solution !== solution) {
    //   return []
    // }
    // const gameWasWon = loaded.guesses.includes(solution)
    // if (gameWasWon) {
    //   setIsGameWon(true)
    // }
    // if (loaded.guesses.length === 6 && !gameWasWon) {
    //   setIsGameLost(true)
    // }
    // return loaded.guesses
    return []
  })

  const gameRef = ref(dbref, "games/" + gameId);
  const tempRef = ref(dbref, "currentGuess/" + gameId);

  //disable
  useEffect(() => {

    console.log(gameId);

    let myIdx = -1;
    let oppIdx = -1;
    let firstGotOpoonent = true;

    const unsubGuesses = onValue(tempRef, (snapshot) => {
      let currentGuessData = snapshot.val();
      if(currentGuessData === null) return;
      if (currentGuessData[oppIdx] !== undefined && currentGuessData[oppIdx] !== null) setOpponentCurrentGuess(currentGuessData[oppIdx]); 
    });

    const unsubGameRef = onValue(gameRef, (snapshot) => {
      let gameData = snapshot.val();

      if(gameData === null) return; //game got cleaned up

      if(gameData.players[0] === myFbUserId) {
        setMyPlayerIdx(0);
        myIdx = 0;
        oppIdx = 1;
      } else if(gameData.players[1] === myFbUserId) {
        setMyPlayerIdx(1);
        myIdx = 1;
        oppIdx = 0;
      }

      if(myIdx === -1) {
        return; //not logged in?
      }

      if(gameData.players[oppIdx] !== undefined && gameData.players[oppIdx] !== null) {
        setOpponentUserId(gameData.players[oppIdx]);
        if(firstGotOpoonent) {
          console.log("FETCH OPOONENT");
          onValue(ref(dbref, "users/" + gameData.players[oppIdx] + "/nickname"), (nickSnap) => {
            setOpponentName(nickSnap.val());
          }, {onlyOnce: true})
          firstGotOpoonent = false;
        }
      }

      if(gameData.solutions[myIdx] === "") {
        setNeedToChooseWord(true);
        console.log("NEED TO CHOOSE WORD"); 
      } else {
        setNeedToChooseWord(false);
        if(gameData.solutions[oppIdx] === "") {
          setWaitingOnOpponentToChoosWord(true);
        } else {
          setWaitingOnOpponentToChoosWord(false);
          if(myIdx === 0) {
            setSolution(gameData.solutions[1]);
            setOpponentSolution(gameData.solutions[0]);
            if(gameData.guesses && gameData.guesses[0]) {
              setGuesses(Object.values(gameData.guesses[0]))
            }
            if(gameData.guesses && gameData.guesses[1]) {
              setOpponentGuesses(Object.values(gameData.guesses[1]));
            }
          } else {
            setSolution(gameData.solutions[0]);
            setOpponentSolution(gameData.solutions[1]);
            if(gameData.guesses && gameData.guesses[1]) {
              setGuesses(Object.values(gameData.guesses[1]))
            }
            if(gameData.guesses && gameData.guesses[0]) {
              setOpponentGuesses(Object.values(gameData.guesses[0]));
            }
          }
        }
      }

      if(gameData.turn === 99) {
        //Game Over
        if(gameData.winner === myIdx) {
          setIsGameWon(true);
        } else {
          setIsGameLost(true);
        }
        setIsMyTurn(true);
        return;
      }

      if(gameData.turn === -1 || gameData.turn % 2 !== myIdx) {
        setIsMyTurn(false);
        setSwapBoardOverride(false);
      } else {
        setIsMyTurn(true);
        setSwapBoardOverride(false);
      }


    })

    return () => {
      unsubGameRef();
      unsubGuesses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameId])

  // useEffect(() => {
  //   if (isDarkMode) {
  //     document.documentElement.classList.add('dark')
  //   } else {
  //     document.documentElement.classList.remove('dark')
  //   }
  // }, [isDarkMode])

  // const handleDarkMode = (is boolean) => {
  //   setIsDarkMode(isDark)
  // }

  // useEffect(() => {
  //   saveGameStateToLocalStorage({ guesses, solution })
  // }, [guesses, solution])

  useEffect(() => {
    if (isGameWon) {
      setSuccessAlert(
        WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)]
      )
      setTimeout(() => {
        setSuccessAlert('')
        setIsStatsModalOpen(true)
      }, ALERT_TIME_MS)
    }
    if (isGameLost) {
      setSuccessAlert(
        CORRECT_WORD_MESSAGE(solution)
      )
      setTimeout(() => {
        setSuccessAlert('')
        setIsStatsModalOpen(true)
      }, ALERT_TIME_MS)
    }
  }, [isGameWon, isGameLost])

  const noOp = () => {
    //do nothing
  }

  const onChar = (value: string) => {
    if (currentGuess.length < 5 && guesses.length < 6 && !isGameWon) {
      updateMyGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    updateMyGuess(currentGuess.slice(0, -1))
  }

  const updateMyGuess = (value : string) => {
    setCurrentGuess(value);
    set(child(tempRef, "" + myPlayerIdx), value);
  }

  const onEnter = () => {
    if (isGameWon || isGameLost) {
      return
    }
    if (!(currentGuess.length === 5)) {
      setIsNotEnoughLetters(true)
      return setTimeout(() => {
        setIsNotEnoughLetters(false)
      }, ALERT_TIME_MS)
    }

    if (!isWordInWordList(currentGuess)) {
      setIsWordNotFoundAlertOpen(true)
      return setTimeout(() => {
        setIsWordNotFoundAlertOpen(false)
      }, ALERT_TIME_MS)
    }

    if(needToChooseWord) {

      console.log("CHOSE WORD: " + currentGuess);
      set(child(gameRef, "solutions/" + myPlayerIdx), currentGuess);
      updateMyGuess("");

    } else {

      let newGuess = currentGuess + "";

      setTimeout(() => {
        push(child(gameRef, "guesses/" + myPlayerIdx), newGuess);
        updateMyGuess("")
      }, 2500);

      if (currentGuess.length === 5 && guesses.length < 6 && !isGameWon) {
        setGuesses([...guesses, currentGuess])
        setCurrentGuess("");
      }
    }
  }

  let gameGrid;

  if(needToChooseWord) {
    gameGrid = <View>
      <Text style={tw`text-xl grow font-bold text-white w-80 mx-auto mb-2`}>Choose your word...</Text>
      <Text style={tw`text-sm grow text-white w-80 mx-auto mb-8`}>Choose the word that your opponent will have to guess.</Text>
      <Grid guesses={[]} currentGuess={currentGuess} solution={""} gridRows={1} />
      <Keyboard
        onChar={onChar}
        onDelete={onDelete}
        onEnter={onEnter}
        guesses={guesses}
        opponentGuesses={[]}
        solution={solution}
      />
    </View>;
  } else if(waitingOnOpponentToChooseWord) {

    let opponentStatus = "Waiting on opponent to choose their word...";
    if(opponentUserId === "") {
      opponentStatus = "Searching for a worthy opponent...";
    }

    gameGrid = <View style={tw`w-80 mx-auto`}>
      <Text style={tw`text-lg grow text-white mb-2`}>{opponentStatus}</Text>
      <Text style={tw`text-xl grow font-bold text-white mb-2`}>How to Play</Text>
      <Text style={tw`text-sm text-gray-500 text-gray-300`}>
        Guess your opponent's word before they guess yours. After each guess, the color of the tiles will
        change to show how close your guess was to the word.
      </Text>

      <View style={tw`flex justify-center mb-1 mt-4 flex-row`}>
        <Cell value="W" status="correct" />
        <Cell value="E" />
        <Cell value="A" />
        <Cell value="R" />
        <Cell value="Y" />
      </View>
      <Text style={tw`text-sm text-gray-500 text-gray-300`}>
        The letter W is in the word and in the correct spot.
      </Text>

      <View style={tw`flex justify-center mb-1 mt-4  flex-row`}>
        <Cell value="P" />
        <Cell value="I" />
        <Cell value="L" status="present" />
        <Cell value="O" />
        <Cell value="T" />
      </View>
      <Text style={tw`text-sm text-gray-500 text-gray-300`}>
        The letter L is in the word but in the wrong spot.
      </Text>

      <View style={tw`flex justify-center mb-1 mt-4  flex-row`}>
        <Cell value="V" />
        <Cell value="A" />
        <Cell value="G" />
        <Cell value="U" status="absent" />
        <Cell value="E" />
      </View>
      <Text style={tw`text-sm text-gray-500 text-gray-300`}>
        The letter U is not in the word in any spot.
      </Text>
      <Text style={tw`text-sm text-gray-500 text-gray-300 mt-4`}>
        Twist: Each time that you guess, any of the letters you use in your guess will be revealed to your opponent if they're in your word as well. Strategize!
      </Text>
    </View>
  } else if((isMyTurn && !swapBoardOverride) || (!isMyTurn && swapBoardOverride)) {
    gameGrid = <View>
      <Grid guesses={guesses} currentGuess={currentGuess} solution={solution} gridRows={6} />
      {isMyTurn && !isGameLost && !isGameWon &&
      <Keyboard
        onChar={onChar}
        onDelete={onDelete}
        onEnter={onEnter}
        guesses={guesses}
        opponentGuesses={opponentGuesses}
        solution={solution}
        disabled={false}
      />
      }
      {!isMyTurn && !isGameLost && !isGameWon &&
      <Keyboard
        onChar={noOp}
        onDelete={noOp}
        onEnter={noOp}
        guesses={guesses}
        opponentGuesses={opponentGuesses}
        solution={solution}
        disabled={true}
      />
      }
    </View>;
  } else {
    gameGrid = <View>
      <Grid guesses={opponentGuesses} currentGuess={opponentCurrentGuess} solution={opponentSolution} gridRows={6} />
      <Text style={{color: "gray"}}>(The secret word you chose was {opponentSolution})</Text>
    </View>;
  }

  let statusText = GAME_TITLE;
  if(!waitingOnOpponentToChooseWord && !needToChooseWord) {
    if(isMyTurn) {
      if(swapBoardOverride) {
        statusText = "Opponent's Board";
      } else {
        statusText = "Your Guess!";
      }
    } else {
      if(swapBoardOverride) {
        statusText = "Your Board";
      } else {
        statusText = "Opponent's Guess...";
      }
    }
  }

  return (
    <View style={tw`max-w-7xl mx-auto sm:px-6 lg:px-8`}>
      <View style={tw`flex w-80 mx-auto items-center mb-8 mt-12 flex-row`}>
        <Text style={tw`text-xl grow font-bold text-white mr-4`}>
          {statusText}
        </Text>
        <TouchableOpacity onPress={() => setIsInfoModalOpen(true)}>
          <InformationCircleIcon
            style={tw`h-6 w-6 cursor-pointer stroke-white mr-4 text-white`}
            
          />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => setSwapBoardOverride(!swapBoardOverride)}>
          <RefreshIcon style={tw`h-6 w-6 mr-4 cursor-pointer stroke-white text-white`} />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => exitGame((isGameLost||isGameWon) ? true : false, gameId, false)}>
          <XIcon
            style={tw`h-6 w-6 cursor-pointer stroke-white text-white`}
            
          />
        </TouchableOpacity>
      </View>
     
      {gameGrid}

      <View style={tw`mt-4 flex mr-4 w-full flex-row justify-center items-center`}>
        <Text style={tw`text-gray-400 mr-20`}>vs {opponentName}</Text>
        {!isGameLost && !isGameWon &&
        <TouchableOpacity onPress={() =>{
          ReactAlert.alert("Confirm", "Are you sure you want to forfeit the game?", 
          [
            {
              text: "Abandon Game",
              onPress: () => {
                getAuth().currentUser?.getIdToken(true).then((token) => {
                  fetch("https://us-central1-wordwar-f9e5c.cloudfunctions.net/abandonGame?token=" + token + "&gameId=" + gameId ).then(resp => resp.text()).then(data => {
                    //exitGame(true, gameId, false)
                  });
                })
              }
            },
            {
              text: "Cancel",
              style: "cancel"
            }
          ]
        );
          
        }}><Text style={tw`text-gray-400`}>Abandon Game</Text></TouchableOpacity>
        }
      </View> 

      {(isGameLost || isGameWon) &&
      <View style={tw`w-full items-center justify-center flex flex-row`}>
        <TouchableOpacity 
        style={tw`mt-2 w-32 h-10 rounded-md px-4 py-2 bg-indigo-600 text-middle mt-4 items-center`}
          onPress={() => {
            exitGame(true, gameId, false);
        }}><Text style={{color: "white"}}>Exit</Text></TouchableOpacity> 

        <TouchableOpacity 
          style={tw`mt-2 w-32 h-10 ml-10 rounded-md px-4 py-2 bg-indigo-600 text-middle mt-4 items-center`}
            onPress={() => {
              exitGame(true, gameId, true);
          }}><Text style={{color: "white"}}>Rematch!</Text></TouchableOpacity> 
      </View>
      }

      <InfoModal
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
      />
      <StatsModal
        isOpen={isStatsModalOpen}
        handleClose={() => setIsStatsModalOpen(false)}
        guesses={guesses}
        isGameLost={isGameLost}
        isGameWon={isGameWon}
        handleShare={() => {
          setSuccessAlert(GAME_COPIED_MESSAGE)
          return setTimeout(() => setSuccessAlert(''), ALERT_TIME_MS)
        }}
        solution={solution}
        myUserId={myFbUserId}
        opponentName={opponentName}
        opponentUserId={opponentUserId}
      />
      <AboutModal
        isOpen={isAboutModalOpen}
        handleClose={() => setIsAboutModalOpen(false)}
      />

      <Alert message={NOT_ENOUGH_LETTERS_MESSAGE} isOpen={isNotEnoughLetters} />
      <Alert
        message={WORD_NOT_FOUND_MESSAGE}
        isOpen={isWordNotFoundAlertOpen}
      />
      <Alert
        message={successAlert}
        isOpen={successAlert !== ''}
        variant="success"
      />
    </View>
  )
}

export default Game

//Todo: Better "Game Over" screen