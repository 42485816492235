import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'
import tw from 'twrnc';
import { View, Text, TextInput, TouchableOpacity } from 'react-native';
import { useState } from 'react';
import {getAuth} from "firebase/auth";

type Props = {
  isOpen: boolean
  handleClose: () => void
  challengeCode: string
  fbUserId: string
  setGame: Function
}

export const ChallengeModal = ({ isOpen, handleClose, challengeCode, fbUserId, setGame }: Props) => {

  const [isFetching, setIsFetching] = useState(false)
  const [enteredCode, setEnteredCode] = useState("");

  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <Text style={tw`text-xl grow font-bold dark:text-white mb-2`}>Challenge a Friend</Text>
      <Text style={tw`text-sm text-gray-500 dark:text-gray-300`}>
        Give a friend your challenge code, or enter your friend's challenge code below if you have it. We'll create a game between the two of you!
      </Text>
      <Text style={tw`text-xl grow font-bold dark:text-white mb-2 mt-5`}>Your Challenge Code</Text>
      <Text style={tw`text-2xl grow font-bold dark:text-white mb-2`}>{challengeCode}</Text>
      <Text style={tw`text-xl grow font-bold dark:text-white mb-2 mt-5`}>Have a Challenge Code?</Text>
      <TextInput autoCapitalize={"characters"} style={{fontSize: 26, minWidth: 130, borderColor: "black", borderWidth: 1, padding: 5, borderRadius: 5, textAlign: "center"}} value={enteredCode} onChangeText={(newText) => setEnteredCode(newText)} />
      <TouchableOpacity
        style={tw`mt-2 w-full text-white rounded-md px-4 py-2 bg-indigo-600 hover:bg-indigo-700 focus:outline-none sm:text-sm items-center`}
        onPress={() => {
            setIsFetching(true);
            getAuth().currentUser?.getIdToken(true).then((token) => {
              fetch("https://us-central1-wordwar-f9e5c.cloudfunctions.net/issueChallenge?token=" + token + "&challengeCode=" + enteredCode.toUpperCase()).then(resp => resp.text()).then(data => {
                  // set(ref(dbref, "users/" + fbUserId + "/activegames/" + data), true);
                  if(data === "invalid") {
                      alert("Invalid Challenge Code");
                      setIsFetching(false);
                  } else {
                      setGame(data);
                  }
              })
            })
        }}
    >
        <Text style={tw`text-white text-md`}>{isFetching ? "Joining..." : "Issue Challenge!"}</Text>
    </TouchableOpacity>
    </BaseModal>
  )
}