import { Cell } from './Cell'
import tw from 'twrnc';
import { View } from 'react-native';

export const EmptyRow = () => {
  const emptyCells = Array.from(Array(5))

  return (
    <View style={tw`flex justify-center mb-1 flex-row`}>
      {emptyCells.map((_, i) => (
        <Cell key={i} />
      ))}
    </View>
  )
}
