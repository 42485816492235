import { Fragment } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import tw from 'twrnc';

type Props = {
  isOpen: boolean
  message: string
  variant?: 'success' | 'warning'
}

const styles = StyleSheet.create({
  modalView: {
    margin: 20,
    borderRadius: 20,
    alignItems: "center",
    elevation: 5,
    position: "absolute",
    top: 10,
    left: 0,
    zIndex: 10000,
    width: "100%"
  }
});

export const Alert = ({ isOpen, message, variant = 'warning' }: Props) => {
  const classes = tw.style(
    'overflow-hidden p-4',
    {
      'bg-rose-200': variant === 'warning',
      'bg-blue-200 z-20': variant === 'success',
    }
  )

  if(!isOpen) {
    return <View></View>
  }

  return (
   
    <View style={styles.modalView}>
      <View style={classes}>
        <Text style={tw`text-sm text-center font-medium text-gray-900`}>
          {message}
        </Text>
      </View>
    </View>
    
  )
}
