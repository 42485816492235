import { StatusBar } from 'expo-status-bar';
import { Button, SafeAreaView, StyleSheet, Text, TouchableOpacity, View, AppState, Platform, TextInput, ScrollView, KeyboardAvoidingView, Image } from 'react-native';
import { useEffect, useState, useRef } from 'react';
import tw from 'twrnc';
import * as Notifications from 'expo-notifications';
import * as Analytics from 'expo-firebase-analytics';
import Constants from 'expo-constants';
import * as Updates from 'expo-updates';
import { uniqueNamesGenerator, adjectives, colors, starWars } from 'unique-names-generator';

import { initializeApp } from 'firebase/app';
const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");
import { getDatabase, ref, set, onValue } from "firebase/database";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import Game from './Game';
import { Cell } from './components/grid/Cell';
import { ChallengeModal } from './components/modals/ChallengeModal';
import { CreditsModal } from './components/modals/CreditsModal';
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDsvG-o1eh5spPqyjwOSeTVro2KgY43-H4",
    authDomain: "wordwar-f9e5c.firebaseapp.com",
    databaseURL: "https://wordwar-f9e5c-default-rtdb.firebaseio.com",
    projectId: "wordwar-f9e5c",
    storageBucket: "wordwar-f9e5c.appspot.com",
    messagingSenderId: "539805360698",
    appId: "1:539805360698:web:d771198264e0625ccacb03",
    measurementId: "G-VPSLLGGB47"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  if(Constants.platform.web) {

    const appCheck = initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider('6LcGmHQfAAAAADl2xwEA5olh4geev8Xnm-nHXBWn'),
    
      // Optional argument. If true, the SDK automatically refreshes App Check
      // tokens as needed.
      isTokenAutoRefreshEnabled: true
    });

  }

  const dbref = getDatabase(app);

  Notifications.setNotificationHandler(null);

const registerForPushNotificationsAsync = async (fbid : string) => {
  if (Constants.isDevice && !Constants.platform.web) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      //alert('Failed to get push token for push notification!');
      return;
    }
    const token = (await Notifications.getExpoPushTokenAsync()).data;
    set(ref(dbref, "users/" + fbid + "/pushtoken"), token);
    //alert("Got token " + token);
  } else {
    //alert('Must use physical device for Push Notifications');
  }

  if (Constants.platform && Constants.platform.android) {
    Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }
  };

 const makeid = (length : Number) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
 charactersLength));
   }
   return result;
}

export default function App() {

    const [gameId, setGameId] = useState("");
    const [isFetching, setIsFetching] = useState(false);
    const [fbUserId, setFbUserId] = useState("");
    const [activeGames, setActiveGames] = useState([]);
    const [nickname, setNickname] = useState("");
    const [showChallengeCode, setShowChallengeCode] = useState(false);
    const [challengeCode, setChallengeCode] = useState("");
    const [showCredits, setShowCredits] = useState(false);

    const notificationListener = useRef();
    const responseListener = useRef();

    const exitGame = (gameEnded:boolean, thisGameId:string, doRematch:boolean) => {
      
        if(gameEnded) {
          console.log("GAME ENDED");
          console.log(thisGameId);
          set(ref(dbref, "users/" + fbUserId + "/activeGames/"+thisGameId), null);
        }

        if(doRematch) {
          fetch("https://us-central1-wordwar-f9e5c.cloudfunctions.net/issueRematch?gameId=" + thisGameId).then(resp => resp.text()).then(data => {
            setIsFetching(false);
            setGameId(data);
          });
        }

        setIsFetching(false);
        setGameId("");
    }

    const updateNickname = (newNickname : string) => {
      setNickname(newNickname)
      set(ref(dbref, "users/" + fbUserId + "/nickname"), newNickname);
    }

    const regenerateChallengeCode = () => {
      const newCode = makeid(6);
      set(ref(dbref, "users/" + fbUserId + "/challengeCode"), newCode);
      set(ref(dbref, "challengeCodes/" + newCode), fbUserId);
      setChallengeCode(newCode);
    }

    useEffect(() => {
        const auth = getAuth();
        signInAnonymously(auth)
        .then(() => {
          // Signed in..
        })
        .catch((error) => {
          console.log(error);
          // ...
        });
        onAuthStateChanged(auth, (user) => {
          console.log("AUTH STATE CHANGED");
            if(user) {
                console.log(user.uid);
                setFbUserId(user.uid);
                Analytics.setUserId(user.uid);
                //registerForPushNotificationsAsync(user.uid);
            }
        });

    }, [])

    useEffect(() => {
      if(fbUserId === "") return;
      registerForPushNotificationsAsync(fbUserId);
      AppState.addEventListener("change", state => {
        if(state == "active") {
          registerForPushNotificationsAsync(fbUserId);
        }
      });
    }, [fbUserId])

    useEffect(() => {

      if(fbUserId === "") return;

      const unsubActiveGames = onValue(ref(dbref, "users/" + fbUserId + "/activeGames"), (snap) => {
        if(snap.val()) {
          let newActiveGames = [];
          const allGames = snap.val();
          for(var j in allGames) {
            newActiveGames.push({
              id: j,
              opponentName: allGames[j].opponentName,
              myTurn: allGames[j].myTurn
            })
          }
          setActiveGames(newActiveGames);
        } else {
          setActiveGames([]);
        }
      });

      onValue(ref(dbref, "users/" + fbUserId + "/nickname"), (snap) => {
        if(snap.val() && snap.val() !== "") {
          setNickname(snap.val())
        } else {
          const capitalizedName: string = uniqueNamesGenerator({
            dictionaries: [adjectives, starWars],
            style: 'capital',
            separator: " "
          });
          updateNickname(capitalizedName)
        }
      }, {onlyOnce: true});

      onValue(ref(dbref, "users/" + fbUserId + "/challengeCode"), (snap) => {
        if(!snap.val()) {
          regenerateChallengeCode();
        } else {
          setChallengeCode(snap.val());
        }
      }, {onlyOnce: true});

      const unsubNotifications = onValue(ref(dbref, "notifications/" + fbUserId), (snap) => {
        if(Constants.platform.web) {
          if(snap.val()) {
            if(new Date().getTime() - snap.val().timestamp < 10*1000) { 
              if(document.hidden) {
                console.log("Show noti"); 
                const thisNotification = new Notification('Battlewords', { body: snap.val().body, icon: "https://battlewords.riley.dev/favicon-32.png" });
              }
            }
          }
        }
      });

      return () => {
        unsubActiveGames();
        unsubNotifications();
      }

    }, [fbUserId])

    if(Platform.OS !== "web" && Updates.updateId !== null) {

      useEffect(() => {
  
        const checkForUpdates = async () => {
  
          const {isAvailable} = await Updates.checkForUpdateAsync();
          if(isAvailable) {
            const {isNew} = await Updates.fetchUpdateAsync();
            if(isNew) {
              await Updates.reloadAsync();
            }
          }
        };
  
        checkForUpdates();
  
        AppState.addEventListener("change", state => {
          if(state == "active") {
            checkForUpdates();
          }
        });
  
      }, []);
  
    }

    useEffect(() => {
       // This listener is fired whenever a notification is received while the app is foregrounded
        notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
          //setNotification(notification);
        });

        // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
        responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
          //console.log(response);
        });

        return () => {
          Notifications.removeNotificationSubscription(notificationListener.current);
          Notifications.removeNotificationSubscription(responseListener.current);
        };
    }, []);

    let isMyTurnInAGame = false;

    const activeGamesList = activeGames.map((item, index) => {
      if(item.myTurn == true) {
        isMyTurnInAGame = true;
      }
      return (
        <TouchableOpacity key={item.id} style={tw`mt-2 w-full text-white rounded-md px-4 py-2 bg-indigo-600 hover:bg-indigo-700 focus:outline-none sm:text-sm items-center`} onPress={() => {
          if(Constants.platform.web) {
            if(window.Notification) Notification.requestPermission();
          }
          setGameId(item.id)
        }}>
          <Text style={tw`text-white`}>{item.myTurn == true ? "✨ " : ""} You vs. {item.opponentName}</Text>
        </TouchableOpacity>
      );
    })

    if(Constants.platform.web) {
      if(isMyTurnInAGame) {
        document.title = "✨ Battlewords";
      } else {
        document.title = "Battlewords";
      }
    }


    if(gameId === "" || gameId === undefined) {

        return (
          <SafeAreaView style={{backgroundColor: "#1e2225", minHeight: "100%"}}>
            <StatusBar
              animated={true}
              backgroundColor="#1e2225"
              style='light'
            />
            <ScrollView style={{margin: 0, padding: 0}}>
          
                <View style={tw`flex justify-center mb-1 mt-10  flex-row`}>
                  <Image source={require("./assets/bigicon.png")} resizeMode={"contain"} style={{width: "80%", height: 200}} />
                </View>

                <View style={{width: "80%", marginLeft: "auto", marginRight: "auto", marginTop: 15, maxWidth: 600}}>
                <Text style={tw`text-sm text-gray-200 dark:text-gray-300`}>
                    Let's play! Press the Random Opponent button below to find an opponent.
                  </Text>
                </View>
                <View style={{display: "flex", flexDirection: "row", width: "75%", marginLeft: "auto", marginRight: "auto", marginTop: 15, maxWidth: 600}}>
                  <Text style={{width: 125, fontSize: 14, paddingTop: 6, color: "white"}}>Your Nickname:</Text>
                  <TextInput style={{width: 175, fontSize: 14, borderColor: "black", borderWidth: 1, borderRadius: 5, padding: 5, backgroundColor: "white"}} value={nickname} onChangeText={(newText) => {
                      updateNickname(newText)
                    }} />
                </View>
                <View style={{width: "80%", marginLeft: "auto", marginRight: "auto", marginTop: 15, maxWidth: 600}}>
                
                <Text style={tw`text-lg font-bold text-white`}>New Game</Text>
                  <TouchableOpacity
                      style={tw`mt-2 w-full text-white rounded-md px-4 py-2 bg-indigo-600 hover:bg-indigo-700 focus:outline-none sm:text-sm items-center`}
                      onPress={() => {
                          setIsFetching(true);
                          if(Constants.platform.web) {
                            if(window.Notification) Notification.requestPermission();
                          }
                          getAuth().currentUser?.getIdToken(true).then((token) => {
                            fetch("https://us-central1-wordwar-f9e5c.cloudfunctions.net/nextGame?token=" + token).then(resp => resp.text()).then(data => {
                                // set(ref(dbref, "users/" + fbUserId + "/activegames/" + data), true);
                                setGameId(data);
                            })
                          });
                      }}
                  >
                      <Text style={tw`text-white text-lg`}>{isFetching ? "Joining..." : "Random Opponent"}</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                      style={tw`mt-2 w-full text-white rounded-md px-4 py-2 bg-indigo-600 hover:bg-indigo-700 focus:outline-none sm:text-sm items-center`}
                      onPress={() => {
                          if(Constants.platform.web) {
                            if(window.Notification) Notification.requestPermission();
                          }
                          setShowChallengeCode(true)
                      }}
                  >
                      <Text style={tw`text-white text-md`}>{isFetching ? "Joining..." : "Challenge a Friend"}</Text>
                  </TouchableOpacity>
                </View>
                <View style={{width: "80%", marginLeft: "auto", marginRight: "auto", marginTop: 15, maxWidth: 600}}>
                  {activeGamesList.length > 0 &&
                  <Text style={tw`text-lg font-bold text-white`}>In-Progress Games</Text>
                  }
                  {activeGamesList}
                </View>
                {Constants.platform.web &&
                <View style={{width: "80%", marginLeft: "auto", marginRight: "auto", marginTop: 30, maxWidth: 600}}>
                  <TouchableOpacity
                        style={tw`mt-2 w-full text-white rounded-md px-4 py-2 hover:bg-indigo-700 focus:outline-none sm:text-sm items-center`}
                        onPress={() => {
                            window.open("https://play.google.com/store/apps/details?id=dev.riley.battlewords")
                        }}
                    >
                        <Text style={tw`text-gray-400 text-md`}>Install the Android App</Text>
                    </TouchableOpacity>
                </View>
                }
                <View style={{width: "80%", marginLeft: "auto", marginRight: "auto", marginTop: 30, maxWidth: 600}}>
                  <TouchableOpacity
                        style={tw`mt-2 w-full text-white rounded-md px-4 py-2 hover:bg-indigo-700 focus:outline-none sm:text-sm items-center`}
                        onPress={() => {
                            setShowCredits(true)
                        }}
                    >
                        <Text style={tw`text-gray-400 text-md`}>Credits</Text>
                    </TouchableOpacity>
                </View>
                <ChallengeModal
                  isOpen={showChallengeCode}
                  handleClose={() => setShowChallengeCode(false)}
                  challengeCode={challengeCode}
                  setGame={(newGameId:string) => {
                    setShowChallengeCode(false)
                    setGameId(newGameId)
                  }}
                  fbUserId={fbUserId}
                />
                <CreditsModal
                  isOpen={showCredits}
                  handleClose={() => setShowCredits(false)}
                />
            </ScrollView>
          </SafeAreaView>
          
        )

    } else {
        return (
          <SafeAreaView style={{backgroundColor: "#1e2225", minHeight: "100%"}}>
            <StatusBar
              animated={true}
              backgroundColor="#1e2225"
              style='light'
            />
            <View style={{maxWidth: 500, marginLeft: "auto", marginRight: "auto"}}>
              <Game gameId={gameId} myFbUserId={fbUserId} exitGame={exitGame} />
            </View>
          </SafeAreaView>
        )
    }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#1e2225',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
