import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";
import { getAuth, signInAnonymously } from "firebase/auth";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDsvG-o1eh5spPqyjwOSeTVro2KgY43-H4",
    authDomain: "wordwar-f9e5c.firebaseapp.com",
    databaseURL: "https://wordwar-f9e5c-default-rtdb.firebaseio.com",
    projectId: "wordwar-f9e5c",
    storageBucket: "wordwar-f9e5c.appspot.com",
    messagingSenderId: "539805360698",
    appId: "1:539805360698:web:d771198264e0625ccacb03",
    measurementId: "G-VPSLLGGB47"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const auth = getAuth();
  signInAnonymously(auth)
  .then(() => {
    // Signed in..
  })
  .catch((error) => {
    console.log(error);
    // ...
  });
  const dbref = getDatabase(app);
  export default dbref;