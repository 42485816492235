import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'
import tw from 'twrnc';
import { View, Text } from 'react-native';

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <Text style={tw`text-xl grow font-bold dark:text-white mb-2`}>How to Play</Text>
      <Text style={tw`text-sm text-gray-500 dark:text-gray-300`}>
        Guess your opponent's word before they guess yours. After each guess, the color of the tiles will
        change to show how close your guess was to the word.
      </Text>

      <View style={tw`flex justify-center mb-1 mt-4 flex-row`}>
        <Cell value="W" status="correct" />
        <Cell value="E" />
        <Cell value="A" />
        <Cell value="R" />
        <Cell value="Y" />
      </View>
      <Text style={tw`text-sm text-gray-500 dark:text-gray-300`}>
        The letter W is in the word and in the correct spot.
      </Text>

      <View style={tw`flex justify-center mb-1 mt-4  flex-row`}>
        <Cell value="P" />
        <Cell value="I" />
        <Cell value="L" status="present" />
        <Cell value="O" />
        <Cell value="T" />
      </View>
      <Text style={tw`text-sm text-gray-500 dark:text-gray-300`}>
        The letter L is in the word but in the wrong spot.
      </Text>

      <View style={tw`flex justify-center mb-1 mt-4  flex-row`}>
        <Cell value="V" />
        <Cell value="A" />
        <Cell value="G" />
        <Cell value="U" status="absent" />
        <Cell value="E" />
      </View>
      <Text style={tw`text-sm text-gray-500 dark:text-gray-300`}>
        The letter U is not in the word in any spot.
      </Text>
      <Text style={tw`text-sm text-gray-500 dark:text-gray-300 mt-4`}>
        Twist: Each time that you guess, any of the letters you use in your guess will be revealed to your opponent if they're in your word as well. Strategize!
      </Text>
    </BaseModal>
  )
}
