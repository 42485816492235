import { CharStatus } from '../../lib/statuses'
import { View, Text } from 'react-native'
import tw from 'twrnc';

type Props = {
  value?: string
  status?: CharStatus
}

export const Cell = ({ value, status }: Props) => {
  const classes = tw.style(
    {
      'w-14 h-14 border-solid border-2 flex items-center justify-center mx-0.5 text-lg font-bold rounded text-white': true,
      'bg-white bg-slate-900 border-slate-200 border-slate-600':
        !status,
      'border-black border-slate-100': value && !status,
      'bg-slate-400 bg-slate-700 text-white border-slate-400 border-slate-700':
        status === 'absent',
      'bg-green-600 text-white border-green-500': status === 'correct',
      'bg-yellow-600 text-white border-yellow-500 border-yellow-700':
        status === 'present',
    }
  )

  return <View style={classes}><Text style={{color: "white"}}>{value}</Text></View>
}
