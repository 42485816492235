import { shareStatus } from '../../lib/share'
import { BaseModal } from './BaseModal'
import {
  STATISTICS_TITLE,
  SHARE_TEXT,
} from '../../constants/strings'

import tw from 'twrnc';
import { View, Text, TouchableOpacity } from 'react-native';
import { useEffect, useState } from 'react';

type Props = {
  isOpen: boolean
  handleClose: () => void
  guesses: string[]
  isGameLost: boolean
  isGameWon: boolean
  handleShare: () => void
  solution: string
  myUserId: string,
  opponentName: string,
  opponentUserId: string
}

export const StatsModal = ({
  isOpen,
  handleClose,
  guesses,
  isGameLost,
  isGameWon,
  handleShare,
  solution,
  myUserId,
  opponentName,
  opponentUserId
}: Props) => {

  const [wins, setWins] = useState(0);
  const [losses, setLosses] = useState(0);
  const [opponentWins, setOpponentWins] = useState(0);
  const [opponentLosses, setOpponentLosses] = useState(0);

  useEffect(() => {

    if(isOpen) {

      fetch("https://us-central1-wordwar-f9e5c.cloudfunctions.net/getStats?userId=" + myUserId + "&opponentId=" + opponentUserId ).then(resp => resp.json()).then(data => {
        console.log("FETCHED STATS");
        setWins(data.wins);
        setLosses(data.losses);
        setOpponentWins(data.winsThisOpponent);
        setOpponentLosses(data.lossesThisOpponent);
      });

    }

  }, [isOpen])

  return (
    <BaseModal
      title={STATISTICS_TITLE}
      isOpen={isOpen}
      handleClose={handleClose}
    >

    {isGameLost &&
    <View>
      <Text style={tw`text-lg font-bold`}>You Lost!</Text>
      <Text>The word was {solution}</Text>
    </View>
    }

    {isGameWon &&
    <View>
      <Text style={tw`text-lg font-bold`}>You Won!</Text>
      <Text>The word was {solution}</Text>
    </View>
    }

    <View>
      <Text style={tw`text-lg font-bold`}>Your Stats</Text>
      <Text>{wins} Wins / {losses} Losses</Text>
      <Text style={tw`text-lg font-bold`}>Versus {opponentName}</Text>
      <Text>{opponentWins} Wins / {opponentLosses} Losses</Text>
    </View>

      {/* {(isGameLost || isGameWon) && (
        <View style={tw`mt-5 sm:mt-6 columns-2 dark:text-white`}>
          <TouchableOpacity
            style={tw`mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm`}
            onPress={() => {
              shareStatus(guesses, solution, isGameLost)
              handleShare()
            }}
          >
            <Text style={{color: "white"}}>{SHARE_TEXT}</Text>
          </TouchableOpacity>
        </View>
      )} */}
    </BaseModal>
  )
}
