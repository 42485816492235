import { Modal, SafeAreaView, StyleSheet, TouchableOpacity, View } from 'react-native'
import { XIcon } from 'react-native-heroicons/outline';
import tw from 'twrnc';

type Props = {
  title: string
  children: React.ReactNode
  isOpen: boolean
  handleClose: () => void
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center"
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center"
  }
});

export const BaseModal = ({ title, children, isOpen, handleClose }: Props) => {
  return (
  <Modal
        animationType="slide"
        transparent={true} 
        visible={isOpen}
        onRequestClose={() => {
          handleClose();
        }}
        style={{maxWidth: 600}}
      >
        <SafeAreaView style={{width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.70)"}}>
            <View style={styles.modalView}>
              <View style={{width: "100%", alignContent: "flex-end", alignItems: "flex-end"}}>
                <TouchableOpacity onPress={() => handleClose()}>
                    <XIcon color={"black"} />
                  </TouchableOpacity>
              </View>
              {children}
            </View>
        </SafeAreaView>
      </Modal>
  );

}
