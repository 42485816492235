import { ReactNode } from 'react'
import { KeyValue } from '../../lib/keyboard'
import { CharStatus } from '../../lib/statuses'
import tw from 'twrnc';
import { TouchableOpacity, Text } from 'react-native';

type Props = {
  children?: ReactNode
  value: KeyValue
  width?: number
  status?: CharStatus
  onClick: (value: KeyValue) => void
  disabled: boolean
}

export const Key = ({
  children,
  status,
  width = 34,
  value,
  onClick,
  disabled
}: Props) => {
  const classes = tw.style(
    'flex items-center justify-center rounded mx-0.5 text-xs font-bold cursor-pointer select-none dark:text-white',
    {
      'bg-slate-200 dark:bg-slate-600 hover:bg-slate-300 active:bg-slate-400':
        !status,
      'bg-slate-400 text-white': status === 'absent',
      'bg-green-500 hover:bg-green-600 active:bg-green-700 text-white':
        status === 'correct',
      'bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-700 dark:bg-yellow-700 text-white':
        status === 'present'
    }
  )

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    onClick(value)
    event.currentTarget.blur()
  }

  return (
    <TouchableOpacity
      style={{ width: width, height: 50, ...classes }}
      onPress={handleClick}
      disabled={disabled}
    >
      <Text>{children || value}</Text>
    </TouchableOpacity>
  )
}
