import { CompletedRow } from './CompletedRow'
import { CurrentRow } from './CurrentRow'
import { EmptyRow } from './EmptyRow'
import tw from 'twrnc';
import { View } from 'react-native';

type Props = {
  guesses: string[]
  currentGuess: string
  solution: string
  gridRows: number
}

export const Grid = ({ guesses, currentGuess, solution, gridRows }: Props) => {
  const empties =
    guesses.length < (gridRows - 1) ? Array.from(Array((gridRows - 1) - guesses.length)) : []

  return (
    <View style={tw`pb-6`}>
      {guesses.map((guess, i) => (
        <CompletedRow key={i} guess={guess} solution={solution} />
      ))}
      {guesses.length < gridRows && <CurrentRow guess={currentGuess} />}
      {empties.map((_, i) => (
        <EmptyRow key={i} />
      ))}
    </View>
  )
}
