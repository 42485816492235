import { KeyValue } from '../../lib/keyboard'
import { getStatuses } from '../../lib/statuses'
import { Key } from './Key'
import { useEffect } from 'react'
import { ENTER_TEXT, DELETE_TEXT } from '../../constants/strings'
import tw from 'twrnc';
import { View } from 'react-native'

type Props = {
  onChar: (value: string) => void
  onDelete: () => void
  onEnter: () => void
  guesses: string[]
  opponentGuesses: string[]
  solution: string,
  disabled: boolean
}

export const Keyboard = ({ onChar, onDelete, onEnter, guesses, opponentGuesses, solution, disabled }: Props) => {
  const charStatuses = getStatuses(guesses, opponentGuesses, solution)

  const onClick = (value: KeyValue) => {
    if (value === 'ENTER') {
      onEnter()
    } else if (value === 'DELETE') {
      onDelete()
    } else {
      onChar(value)
    }
  }

  // useEffect(() => {
  //   const listener = (e: KeyboardEvent) => {
  //     if (e.code === 'Enter') {
  //       onEnter()
  //     } else if (e.code === 'Backspace') {
  //       onDelete()
  //     } else {
  //       const key = e.key.toUpperCase()
  //       if (key.length === 1 && key >= 'A' && key <= 'Z') {
  //         onChar(key)
  //       }
  //     }
  //   }
  //   window.addEventListener('keyup', listener)
  //   return () => {
  //     window.removeEventListener('keyup', listener)
  //   }
  // }, [onEnter, onDelete, onChar])

  return (
    <View>
      <View style={tw`flex flex-row justify-center mb-1`}>
        <Key disabled={disabled} value="Q" onClick={onClick} status={charStatuses['Q']} />
        <Key disabled={disabled} value="W" onClick={onClick} status={charStatuses['W']} />
        <Key disabled={disabled} value="E" onClick={onClick} status={charStatuses['E']} />
        <Key disabled={disabled} value="R" onClick={onClick} status={charStatuses['R']} />
        <Key disabled={disabled} value="T" onClick={onClick} status={charStatuses['T']} />
        <Key disabled={disabled} value="Y" onClick={onClick} status={charStatuses['Y']} />
        <Key disabled={disabled} value="U" onClick={onClick} status={charStatuses['U']} />
        <Key disabled={disabled} value="I" onClick={onClick} status={charStatuses['I']} />
        <Key disabled={disabled} value="O" onClick={onClick} status={charStatuses['O']} />
        <Key disabled={disabled} value="P" onClick={onClick} status={charStatuses['P']} />
      </View>
      <View style={tw`flex flex-row justify-center mb-1`}>
        <Key disabled={disabled} value="A" onClick={onClick} status={charStatuses['A']} />
        <Key disabled={disabled} value="S" onClick={onClick} status={charStatuses['S']} />
        <Key disabled={disabled} value="D" onClick={onClick} status={charStatuses['D']} />
        <Key disabled={disabled} value="F" onClick={onClick} status={charStatuses['F']} />
        <Key disabled={disabled} value="G" onClick={onClick} status={charStatuses['G']} />
        <Key disabled={disabled} value="H" onClick={onClick} status={charStatuses['H']} />
        <Key disabled={disabled} value="J" onClick={onClick} status={charStatuses['J']} />
        <Key disabled={disabled} value="K" onClick={onClick} status={charStatuses['K']} />
        <Key disabled={disabled} value="L" onClick={onClick} status={charStatuses['L']} />
      </View>
      <View style={tw`flex flex-row justify-center`}>
        <Key disabled={disabled} width={54} value="ENTER" onClick={onClick}>
          {ENTER_TEXT}
        </Key>
        <Key disabled={disabled} value="Z" onClick={onClick} status={charStatuses['Z']} />
        <Key disabled={disabled} value="X" onClick={onClick} status={charStatuses['X']} />
        <Key disabled={disabled} value="C" onClick={onClick} status={charStatuses['C']} />
        <Key disabled={disabled} value="V" onClick={onClick} status={charStatuses['V']} />
        <Key disabled={disabled} value="B" onClick={onClick} status={charStatuses['B']} />
        <Key disabled={disabled} value="N" onClick={onClick} status={charStatuses['N']} />
        <Key disabled={disabled} value="M" onClick={onClick} status={charStatuses['M']} />
        <Key disabled={disabled} width={54} value="DELETE" onClick={onClick}>
          {DELETE_TEXT}
        </Key>
      </View>
    </View>
  )
}
